import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Slider from 'react-slick';
import { Grid } from '@material-ui/core';
import { Typography as SwagupTypography } from '@swagup-com/components';
import { makeStyles } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import collectionTopGutterStyles from './collectionTopGutter.styles';
import { SlickCarouselLeftArrow, SlickCarouselRightArrow } from '../../shared/icons';
import { NavigateTo, TruncateText } from '../../shared/ui/common';
import { useResponsive } from '../../hooks';
import staffPick from '../../assets/images/collections/staff_picks.png';

const ArrowRight = props => {
  const { className, style, onClick } = props;
  return (
    <Grid container>
      <SlickCarouselRightArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />
    </Grid>
  );
};

const ArrowLeft = props => {
  const { className, style, onClick } = props;
  return <SlickCarouselLeftArrow className={className} style={{ ...style, display: 'block' }} onClick={onClick} />;
};

const CollectionTopGutter = ({ collectionsData = [], isFetched, setBannerImage, setMatchingCollection }) => {
  const history = useHistory();
  const location = useLocation();
  const selectedCollection = location.pathname.split('/').pop();
  const { currentDevice, DEVICES_TYPE } = useResponsive();

  const useStyles = makeStyles(theme => collectionTopGutterStyles(theme, currentDevice, DEVICES_TYPE));

  const classes = useStyles();

  const handleNavigate = name => {
    setBannerImage('');
    setMatchingCollection(null);
    const formattedName = encodeURIComponent(name.toLowerCase());
    const navigationUrl =
      name === collectionsData[0]?.name ? '/catalog/collections' : `/catalog/collections/${formattedName}`;
    NavigateTo(history, navigationUrl);
  };

  const settings = {
    slidesToShow: 9,
    slidesToScroll: 5,
    arrows: true,
    infinite: false,
    nextArrow: <ArrowRight className={classes.slickArrow} />,
    prevArrow: <ArrowLeft className={classes.slickArrow} />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 4
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: false
        }
      },
      {
        breakpoint: 968,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: false
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          arrows: false
        }
      },
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      },
      {
        breakpoint: 325,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          arrows: false,
          variableWidth: true
        }
      }
    ]
  };

  return (
    <Grid
      container
      className={classes.container}
      style={{ height: currentDevice === DEVICES_TYPE.MOBILE ? '130px' : '150px' }}
    >
      {isFetched ? (
        <>
          <Slider {...settings} className={classes.slider}>
            {collectionsData?.map((collection, index) => {
              const isSelected =
                (location.pathname === '/catalog/collections' && index === 0) ||
                collection.slug.toLowerCase().replace(/ /g, '-') === selectedCollection;
              const url = collection?.media?.find(mediaItem => mediaItem.mediaCategory === 'product')?.url || staffPick;
              return (
                <Grid
                  key={collection.name}
                  className={`${classes.collectionItem} ${isSelected ? classes.active : ''}`}
                  onClick={() => handleNavigate(collection.slug)}
                >
                  <img src={url} alt={collection.name} className={classes.imageCollections} />
                  <SwagupTypography variant="body3RegularInter" className={classes.collectionName}>
                    {TruncateText(collection.name, 15)}
                  </SwagupTypography>
                </Grid>
              );
            })}
          </Slider>
        </>
      ) : (
        <div style={{ display: 'flex' }}>
          {new Array(9).fill('').map((_val, index) => (
            <Grid key={`collectionGutter${index}`} className={classes.collectionItem}>
              <Skeleton variant="rectangular" width={140} height={140} />
            </Grid>
          ))}
        </div>
      )}
    </Grid>
  );
};

export default CollectionTopGutter;
