import React, { useState, useRef, useCallback, useEffect } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CircularProgress,
  Divider,
  Grid,
  Button
} from '@material-ui/core';
// eslint-disable-next-line import/no-extraneous-dependencies
import { makeStyles } from '@material-ui/styles';
import { Typography as SwagupTypography, Typography } from '@swagup-com/components';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useHistory } from 'react-router-dom';
import Scrollable from 'react-scrollbars-custom';
import Confetti from 'react-canvas-confetti';
import { round } from 'lodash';
import styles from './checkoutPage.styles';
import ContactEmail from '../../shared/ui/contactEmail';
import ContactDetails from '../../shared/ui/contactDetails';
import ProjectDetails from './projectDetails';
import useResponsive from '../../hooks/useResponsive';
import { CommonButton } from '../../shared/ui/commonButtons';
import { RightArrowIcon } from '../../shared/icons';
import { shoppingExperienceColors } from '../../shared/constants';
import { BackToTop, DollarPriceSymbol, MembershipDiscountTextBadge, NavigateTo } from '../../shared/ui/common';
import { Helmet } from '../../../components/shared';
import seoTags from '../../../apis/seoTags';
import Tooltip from '../../../components/shared/Tooltip';
import { useCartContext } from '../../context/cart.context';
import { CART_TYPES } from '../../utils/constants';
import StrikeOut from '../../shared/ui/StrikeOut';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import useDiscountPrice from '../../hooks/useDiscountPrice';
import CheckoutPageAssociatedAccounts from '../../components/Checkout/checkoutPageAssociatedAccounts';
import { useCheckoutContext } from '../../context/checkout.context';
import { createCheckoutOrder, getCartInfo, setCartInfo } from '../../sevices/checkout.service';
import CartAccordionItem from './cartAccordionItem';
import Modal from '../../shared/ui/modal';
import emptyImage from '../../assets/images/checkoutPage/empty.svg';

const useStyles = makeStyles(styles);

const GuestCheckout = props => {
  const { onOpen, setDefultEmail } = props;
  const classes = useStyles();
  const history = useHistory();
  const {
    cartItems,
    cartProducts,
    clearCartItems,
    qtyForPack,
    totalEstimationWithDiscount,
    totalEstimationWithoutDiscount,
    totalPricePerPackWithDiscount = 0,
    totalPricePerPackWithoutDiscount = 0,
    getWithAndWithoutDiscountedPricePerProduct
  } = useCartContext();
  const [isVerified, setIsVerified] = useState(false);
  const [contactEmail, setContactEmail] = useState('');
  const [contactFormValues, setContactFormValues] = useState({
    fullName: '',
    companyName: ''
  });
  const { currentDevice, DEVICES_TYPE, customRange } = useResponsive({ min: 960, max: 1024 });
  const [cartType, setCartType] = useState('');
  const isPack = cartType === CART_TYPES.PACK;
  const [openModal, setOpenModal] = useState(false);
  const { isAuthenticated } = useAuth();
  const { productDiscount } = useDiscountPrice();
  const MembershipDiscountAmount = round(totalEstimationWithoutDiscount - totalEstimationWithDiscount, 2);

  const { selectedAccount, profile, switchingAccounts } = useCheckoutContext();

  const openIntercomHelp = () => {
    console.log('Opening Intercom Chatbot');
    if (typeof Intercom !== 'undefined') {
      Intercom('showSpace', 'home');
    }
  };

  useEffect(() => {
    if (cartItems?.length === 0) {
      console.log('No. of cart items: ', cartItems?.length);
      NavigateTo(history, '/cart');
    }
  }, [cartItems, history]);

  useEffect(() => {
    const storedCartType = localStorage.getItem('cartType');
    if (storedCartType) {
      setCartType(storedCartType);
    }
  }, []);

  useEffect(() => {
    setCartInfo({ totalEstimate: totalEstimationWithDiscount });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalEstimationWithDiscount]);

  useEffect(() => {
    if (contactEmail === '') return;
    setDefultEmail(contactEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactEmail]);

  const confettiInstance = useRef(null);

  const getInstance = useCallback(instance => {
    confettiInstance.current = instance;
  }, []);

  const fireworkConfetti = button => {
    if (confettiInstance.current && button) {
      const { x, y, width, height } = button.getBoundingClientRect();
      const originX = (x + width / 2) / window.innerWidth;
      const originY = (y + height / 2) / window.innerHeight;

      confettiInstance.current({
        particleCount: 120,
        spread: 120,
        startVelocity: 30,
        decay: 0.9,
        scalar: 1.2,
        origin: { x: originX, y: originY }
      });
    }
  };

  const handleContactFormChange = (field, value) => {
    setContactFormValues(prevValues => ({ ...prevValues, [field]: value }));
    setCartInfo({ [field]: value });
  };

  const contactDetailsRef = useRef(null);
  const isProjectDetailsEnabled =
    contactFormValues.fullName.trim() !== '' && contactFormValues.companyName.trim() !== '';

  const [isSubmitEnabled, setIsSubmitEnabled] = useState(isVerified && isProjectDetailsEnabled);
  const [isProjectSubmitted, setIsProjectSubmitted] = useState(false);

  const onCloseModal = () => setOpenModal(false);
  const onOpenModal = () => setOpenModal(true);

  const handleVerification = verified => {
    setIsVerified(verified);
    setIsSubmitEnabled(verified && isProjectDetailsEnabled);
  };

  const handleSubmit = async e => {
    onCloseModal();
    if (isProjectSubmitted) return;
    const cartInfo = { ...getCartInfo(), ...{ qtyForPack } };
    setIsProjectSubmitted(true);
    try {
      const response = await createCheckoutOrder(isAuthenticated, cartItems, cartInfo, !isPack);
      setIsProjectSubmitted(false);
      e.preventDefault();
      if (response.status < 300) {
        clearCartItems();
        fireworkConfetti(e.currentTarget);

        const contactDetailsValues = contactDetailsRef.current ? await contactDetailsRef.current.submit() : null;
        localStorage.setItem('contactEmail', contactEmail);

        if (contactDetailsValues) {
          console.log('Final Submitted Values:', contactDetailsValues);
          const { fullName, companyName, phone } = contactDetailsValues;
          localStorage.setItem('contactDetails', JSON.stringify({ fullName, companyName, phone }));
        } else {
          console.log('ContactDetails validation failed.');
        }

        NavigateTo(history, '/project-submission-confirmation');
      }
    } catch (err) {
      onOpenModal();
      console.error(err);
      setIsProjectSubmitted(false);
    }
  };

  const toolTipTitle = (
    <SwagupTypography variant="body3RegularInter">
      Please complete the form in order to submit your project
    </SwagupTypography>
  );

  const packQuantity = parseInt(localStorage.getItem('pack_quantity'), 10) || 1;

  const TotalEstimate = () => (
    <>
      {isAuthenticated && productDiscount !== 0 && (
        <>
          <Grid container className={classes.subTotalEstimate}>
            <Grid item>
              <SwagupTypography variant="body2SemiBoldInter">Subtotal Estimate</SwagupTypography>
            </Grid>
            <Grid item>
              <StrikeOut value={totalEstimationWithoutDiscount} />
            </Grid>
            <Grid container className={classes.subTotalEstimate} style={{ width: '100%', marginTop: '20px' }}>
              <MembershipDiscountTextBadge text="Membership Discounts" typographyVariant="body3SemiBoldInter" />
              <SwagupTypography variant="body3SemiBoldInter" style={{ color: shoppingExperienceColors.primary }}>
                -{DollarPriceSymbol(MembershipDiscountAmount)}
              </SwagupTypography>
            </Grid>
          </Grid>
          <Divider style={{ marginBottom: 20 }} />
        </>
      )}
      <Grid container className={classes.totalEstimate}>
        <Grid item>
          <SwagupTypography variant="body2SemiBoldInter">Total Estimate</SwagupTypography>
        </Grid>
        <Grid item>
          <SwagupTypography variant="h5SemiBoldInter">
            {DollarPriceSymbol(totalEstimationWithDiscount)}
          </SwagupTypography>
        </Grid>
      </Grid>
      {isPack && (
        <>
          <Grid container className={classes.totalEstimate} style={{ padding: '5px 0px' }}>
            <Grid item>
              <SwagupTypography variant="body3RegularInter">Price Per Pack</SwagupTypography>
            </Grid>
            <Grid item>
              {isAuthenticated && productDiscount !== 0 ? (
                <Grid container style={{ gap: 12 }}>
                  <SwagupTypography variant="body3RegularInter">
                    {DollarPriceSymbol(totalPricePerPackWithDiscount)}
                  </SwagupTypography>
                  <StrikeOut value={totalPricePerPackWithoutDiscount} />
                </Grid>
              ) : (
                <SwagupTypography variant="body3RegularInter">
                  {DollarPriceSymbol(totalPricePerPackWithoutDiscount)}
                </SwagupTypography>
              )}
            </Grid>
          </Grid>
          <Grid container className={classes.totalEstimate} style={{ padding: '5px 0px' }}>
            <Grid item>
              <SwagupTypography variant="body3RegularInter">Pack Quantity</SwagupTypography>
            </Grid>
            <Grid item>
              <SwagupTypography variant="body3RegularInter">{packQuantity}</SwagupTypography>
            </Grid>
          </Grid>
        </>
      )}
      <Grid container style={{ marginTop: '4px' }}>
        <Grid item>
          <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.ctaHover }}>
            (Not including taxes and fees)
          </SwagupTypography>
        </Grid>
      </Grid>
      <Grid container>
        {!isSubmitEnabled ? (
          <Tooltip
            title={toolTipTitle}
            width="100%"
            placement="bottom"
            backgroundColor={shoppingExperienceColors.white}
            fontColor={shoppingExperienceColors.textPrimary}
          >
            <Grid item style={{ width: '100%' }}>
              <CommonButton
                id="project-submit"
                w="100%"
                v="buttonMediumInter"
                hasIcon
                isActive
                text="Submit Project"
                onClick={e => {
                  handleSubmit(e);
                }}
                disabled={!isSubmitEnabled || switchingAccounts}
                icon={
                  isProjectSubmitted ? (
                    <CircularProgress size={14} style={{ color: shoppingExperienceColors.white }} />
                  ) : (
                    <RightArrowIcon color={shoppingExperienceColors.white} fontSize={20} />
                  )
                }
                disabledVariant="active-disabled"
              />
            </Grid>
          </Tooltip>
        ) : (
          <Grid item style={{ width: '100%' }}>
            <CommonButton
              id="project-submit"
              w="100%"
              v="buttonMediumInter"
              hasIcon
              isActive
              text="Submit Project"
              onClick={e => {
                handleSubmit(e);
              }}
              disabled={!isSubmitEnabled || switchingAccounts}
              icon={
                isProjectSubmitted ? (
                  <CircularProgress size={14} style={{ color: shoppingExperienceColors.white }} />
                ) : (
                  <RightArrowIcon color={shoppingExperienceColors.white} fontSize={20} />
                )
              }
              disabledVariant="active-disabled"
            />
          </Grid>
        )}
      </Grid>
      <Grid item container className={classes.noCCInfo}>
        <SwagupTypography variant="body3RegularInter" style={{ color: shoppingExperienceColors.ctaHover }}>
          No credit card info required
        </SwagupTypography>
      </Grid>
    </>
  );

  const InTheCart = () => {
    const AccordionTitle = cartItems?.length === 1;

    const ItemsInCart = () => {
      const AccordionDynamicHeight = cartItems?.length;

      return (
        <Scrollable
          className={classes.scrollBar}
          style={{
            height:
              AccordionDynamicHeight > 3
                ? '160px'
                : AccordionDynamicHeight === 1
                ? '60px'
                : `${AccordionDynamicHeight * 60}px`,
            width: '100%'
          }}
        >
          <Grid container>
            {cartItems.map(item => {
              const product = cartProducts.find(cartProduct => cartProduct.cartId === item.cartId);

              const { totalPriceWithDiscount, totalPriceWithoutDiscount } = getWithAndWithoutDiscountedPricePerProduct(
                product
              );

              return (
                <Grid item key={item} className={classes.cartItems}>
                  <SwagupTypography variant="body3RegularInter" style={{ width: 'fit-content' }}>
                    {item.name}
                  </SwagupTypography>
                  {isAuthenticated && productDiscount !== 0 ? (
                    <Grid container style={{ justifyContent: 'end', gap: 14 }}>
                      <SwagupTypography variant="body3RegularInter">
                        {DollarPriceSymbol(totalPriceWithDiscount)}
                      </SwagupTypography>
                      <StrikeOut value={totalPriceWithoutDiscount} />
                    </Grid>
                  ) : (
                    <SwagupTypography variant="body3RegularInter">
                      {DollarPriceSymbol(totalPriceWithoutDiscount)}
                    </SwagupTypography>
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Scrollable>
      );
    };

    return (
      <>
        <Grid container className={classes.cartContainer}>
          <Grid item style={{ marginBottom: '20px' }}>
            <SwagupTypography variant="h5SemiBoldInter">In Your Cart</SwagupTypography>
            <SwagupTypography variant="body3RegularInter">
              Total ({cartItems?.length} {AccordionTitle ? 'product' : 'products'})
            </SwagupTypography>
          </Grid>
          <Grid item onClick={() => NavigateTo(history, '/cart')}>
            <SwagupTypography
              variant="body3RegularInter"
              style={{ color: shoppingExperienceColors.primary, textDecoration: 'underline', cursor: 'pointer' }}
            >
              Edit
            </SwagupTypography>
          </Grid>
          <ItemsInCart />
        </Grid>
      </>
    );
  };

  const ErrorComponent = () => {
    return (
      <Grid style={{ display: 'block', textAlign: 'center', padding: 36 }}>
        <img src={emptyImage} alt="empty" />
        <Typography variant="h5SemiBoldInter" style={{ paddingTop: 20 }}>
          Error submiting project
        </Typography>
        <Typography variant="body2RegularInter" style={{ paddingTop: 20 }}>
          Please try again or contact support
        </Typography>
        <div style={{ display: 'inline-grid' }}>
          <Button
            className={classes.buttonStyle}
            variant="contained"
            onClick={e => {
              handleSubmit();
            }}
          >
            <Typography variant="body2RegularInter" style={{ color: '#ffff' }}>
              Try again{' '}
            </Typography>
          </Button>
          <Button
            className={classes.textButtonStyle}
            variant="text"
            onClick={e => {
              openIntercomHelp();
            }}
          >
            <Typography variant="buttonSmallInter" style={{ color: shoppingExperienceColors.primary }}>
              Contact support
            </Typography>
          </Button>
        </div>
      </Grid>
    );
  };

  const CartAccordion = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = () => {
      setExpanded(prev => !prev);
    };

    return (
      <Accordion
        className={classes.accordion}
        style={expanded ? { height: '425px' } : undefined}
        onChange={handleChange}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />} className={classes.accordionSummaryCart}>
          <SwagupTypography variant="subtitle3SemiBoldInter">{cartItems?.length} Products</SwagupTypography>
        </AccordionSummary>
        <AccordionDetails>
          <Scrollable style={{ height: 300, width: '100%' }} className={classes.scrollBar}>
            {cartItems.map(item => (
              <CartAccordionItem key={item?.name} item={item} />
            ))}
          </Scrollable>
        </AccordionDetails>
      </Accordion>
    );
  };

  useEffect(() => {
    if (selectedAccount?.name && profile?.email) {
      setContactFormValues({
        companyName: selectedAccount.name,
        fullName: `${profile.first_name} ${profile.last_name}`
      });
      setIsVerified(true);
      setIsSubmitEnabled(true);
    }
  }, [selectedAccount, profile]);

  return (
    <>
      <Helmet tags={seoTags.CXOpages} />
      <Modal title="" open={openModal} onClose={onCloseModal} innerClasses={{ paper: classes.dialog }} hasLogo={false}>
        <ErrorComponent />
      </Modal>
      <form onSubmit={handleSubmit}>
        <Grid container style={{ marginBottom: '50px' }}>
          <Confetti refConfetti={getInstance} className={classes.confetti} />
          <SwagupTypography
            variant="h3SemiBoldInter"
            className={classes.addProjectDetails}
            style={{
              marginBottom:
                currentDevice === DEVICES_TYPE.MOBILE ? '20px' : currentDevice === DEVICES_TYPE.TABLET ? '60px' : '40px'
            }}
          >
            Add project details
          </SwagupTypography>
          {currentDevice === DEVICES_TYPE.MOBILE && (
            <Grid container xs={12} sm={12} className={classes.mobileTotalEstimate}>
              <Grid item>
                <SwagupTypography variant="body3RegularInter">Total Estimate</SwagupTypography>
              </Grid>
              <Grid
                item
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '10px',
                  flexWrap: 'wrap',
                  justifyContent: 'center'
                }}
              >
                <SwagupTypography variant="body3RegularInter">({cartItems?.length} products)</SwagupTypography>
                {isAuthenticated && productDiscount !== 0 ? (
                  <Grid container style={{ width: '100%', flexDirection: 'column', alignItems: 'center' }}>
                    <Grid container style={{ justifyContent: 'center', alignItems: 'center', gap: 14 }}>
                      <SwagupTypography variant="body1SemiBoldInter">
                        {DollarPriceSymbol(totalEstimationWithDiscount)}
                      </SwagupTypography>
                      <StrikeOut value={totalEstimationWithoutDiscount} />
                    </Grid>
                    <Grid container style={{ justifyContent: 'center', alignItems: 'center', marginTop: 14, gap: 6 }}>
                      <SwagupTypography
                        variant="body3SemiBoldInter"
                        style={{ color: shoppingExperienceColors.primary }}
                      >
                        Membership Discounts
                      </SwagupTypography>
                      <SwagupTypography
                        variant="body3SemiBoldInter"
                        style={{ color: shoppingExperienceColors.primary }}
                      >
                        - &nbsp;
                        {DollarPriceSymbol(totalEstimationWithoutDiscount - totalEstimationWithDiscount)}
                      </SwagupTypography>
                    </Grid>
                  </Grid>
                ) : (
                  <SwagupTypography variant="body1SemiBoldInter">
                    {DollarPriceSymbol(totalEstimationWithoutDiscount)}
                  </SwagupTypography>
                )}
              </Grid>
            </Grid>
          )}
          <Grid container style={{ padding: currentDevice === DEVICES_TYPE.MOBILE ? '0px 20px' : '0px 80px' }}>
            <Grid item xs={12} sm={12} md={6} lg={8} xl={8}>
              {isAuthenticated ? (
                <CheckoutPageAssociatedAccounts
                  isVerified={isVerified}
                  onChange={handleContactFormChange}
                  ref={contactDetailsRef}
                />
              ) : (
                <>
                  <Grid container item className={classes.contactEmail}>
                    <SwagupTypography
                      variant={currentDevice === DEVICES_TYPE.MOBILE ? 'body1SemiBoldInter' : 'h5SemiBoldInter'}
                    >
                      Contact
                    </SwagupTypography>
                    <ContactEmail
                      onVerify={handleVerification}
                      onEmailChange={email => {
                        setContactEmail(email);
                        setCartInfo({ email });
                      }}
                      onOpen={onOpen}
                    />
                  </Grid>
                  <Grid item style={{ margin: '12px 0px' }}>
                    <ContactDetails
                      isVerified={isVerified}
                      onChange={handleContactFormChange}
                      ref={contactDetailsRef}
                    />
                  </Grid>
                </>
              )}
              <Grid item>
                <ProjectDetails
                  isEnabled={isProjectDetailsEnabled}
                  setSubmitEnabled={setIsSubmitEnabled}
                  budgetPerPack={Math.ceil(
                    isAuthenticated ? totalPricePerPackWithoutDiscount : totalPricePerPackWithDiscount
                  )}
                />
              </Grid>
              {(!isSubmitEnabled || switchingAccounts) && currentDevice !== DEVICES_TYPE.MOBILE ? (
                <Tooltip
                  title={toolTipTitle}
                  width="100%"
                  placement="bottom"
                  backgroundColor={shoppingExperienceColors.white}
                  fontColor={shoppingExperienceColors.textPrimary}
                >
                  <span style={{ display: 'inline-grid', width: '185px', height: '65px' }}>
                    <CommonButton
                      id="project-submit"
                      w={185}
                      v="buttonMediumInter"
                      hasIcon
                      isActive={false}
                      outlined
                      text="Submit Project"
                      onClick={handleSubmit}
                      disabled
                      icon={
                        isProjectSubmitted ? (
                          <CircularProgress size={14} style={{ color: shoppingExperienceColors.black }} />
                        ) : (
                          <RightArrowIcon fontSize={20} />
                        )
                      }
                      disabledVariant="outlined-disabled"
                    />
                  </span>
                </Tooltip>
              ) : isSubmitEnabled && currentDevice === DEVICES_TYPE.MOBILE ? (
                <Grid item style={{ width: '100%' }}>
                  <CommonButton
                    id="project-submit"
                    w="100%"
                    v="buttonMediumInter"
                    hasIcon
                    isActive
                    text="Submit Project"
                    onClick={e => {
                      handleSubmit(e);
                    }}
                    disabled={!isSubmitEnabled || switchingAccounts}
                    icon={
                      isProjectSubmitted ? (
                        <CircularProgress size={14} style={{ color: shoppingExperienceColors.white }} />
                      ) : (
                        <RightArrowIcon color={shoppingExperienceColors.white} fontSize={20} />
                      )
                    }
                    disabledVariant="active-disabled"
                  />
                </Grid>
              ) : (
                currentDevice !== DEVICES_TYPE.MOBILE && (
                  <CommonButton
                    id="project-submit"
                    w={185}
                    v="buttonMediumInter"
                    hasIcon
                    isActive={false}
                    outlined
                    text="Submit Project"
                    onClick={handleSubmit}
                    disabled={false}
                    icon={
                      isProjectSubmitted ? (
                        <CircularProgress size={14} style={{ color: shoppingExperienceColors.black }} />
                      ) : (
                        <RightArrowIcon fontSize={20} />
                      )
                    }
                  />
                )
              )}
            </Grid>
            {currentDevice === DEVICES_TYPE.MOBILE && (
              <SwagupTypography
                variant="body3RegularInter"
                style={{ color: shoppingExperienceColors.primary, margin: '20px auto' }}
              >
                No credit card info required
              </SwagupTypography>
            )}
            {currentDevice !== DEVICES_TYPE.MOBILE && (
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={4}
                xl={4}
                style={{
                  padding: customRange
                    ? '0px 20px' // Specific css for 960px–1024px
                    : currentDevice === DEVICES_TYPE.MOBILE
                    ? '20px 0px'
                    : currentDevice === DEVICES_TYPE.TABLET
                    ? '30px 0px'
                    : '0px 40px'
                }}
              >
                <TotalEstimate />
                <Divider style={{ marginBottom: '20px' }} />
                <InTheCart />
                <Divider style={{ margin: '20px 0px' }} />
                <CartAccordion />
              </Grid>
            )}
          </Grid>
        </Grid>
      </form>
      {currentDevice === DEVICES_TYPE.MOBILE && isSubmitEnabled && <BackToTop />}
    </>
  );
};

export default GuestCheckout;
