export const differentHeaderPaths = ['/start-project'];
export const hideFooterPaths = ['/start-project'];
export const hideBlueBannerPaths = ['/start-project', '/project-submission', '/project-submission-confirmation'];
export const formHeaderPaths = ['/project-submission'];
export const formFooterPaths = ['/project-submission'];
export const removeMarginTopOnIndex = ['/project-submission'];
export const MAX_PRODUCT_PER_PAGE = 20;

export const quantityBreaksArr = [25, 50, 100, 150, 250, 500, 1000];
export const CART_TYPES = {
  BULK: 'bulk',
  PACK: 'pack'
};
export const defaultMinimumQtyForPacks = 25;
export const defaultQtyForPack = 100;
export const defaultQtyForBulk = 100;
export const MAXIMUM_QUANTITY_FOR_PRODUCT_PER_PACK = 10;
export const sortData = [
  {
    _id: '',
    name: 'Sort by',
    fieldName: 'sort.by',
    inputType: 'sort',
    multiselect: false,
    type: 'string',
    values: [
      {
        label: 'Newest',
        parameter: {
          field: 'Newest',
          sortOrder: 'HIGH'
        }
      },
      {
        label: 'Oldest',
        parameter: {
          field: 'Newest',
          sortOrder: 'LOW'
        }
      }
    ]
  }
];

// Define the custom order for all possible sizes
export const sizeOrder = [
  // General Descriptive Sizes
  'one size',
  'free size',
  'plus size',
  'petite',
  'tall',
  'regular',
  'short',
  'long',

  // Standard Sizes
  'xxs',
  'xs',
  's',
  'm',
  'l',
  'xl',
  '2xl',
  'xxl',
  '3xl',
  'xxxl',
  '4xl',
  '5xl',

  // Numeric Sizes
  '0',
  '2',
  '4',
  '6',
  '8',
  '10',
  '12',
  '14',
  '16',
  '18',
  '20',
  '22',
  '24',
  '28',
  '30',
  '32',
  '34',
  '36',
  '38',
  '40',

  // Letter-Numeric Combos
  'xs/s',
  's/m',
  'm/l',
  'l/xl',
  'xl/2xl',

  // Specialty Sizes
  'custom size',
  'adjustable',
  'oversized',
  'slim fit',
  'regular fit',
  'relaxed fit',

  // Children's Sizes
  'nb',
  '0-3m',
  '3-6m',
  '6-12m',
  '12m',
  '2t',
  '3t',
  '4t',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
  '10',
  '12',
  'teen',

  // Industry-Specific Sizes
  'standard',
  'king size',
  'queen size',
  'junior size',
  'misses size',

  // Regional Variations
  'eu',
  'uk',
  'us',
  'asia'
];

export const FILTER_FIELD_NAMES = {
  SOLD_BY: 'vendorIds',
  PRICING_OPTION: 'variants.pricingOption.pricingMatrix.price.value',
  PRICE_RANGE_MIN: 'priceRange.min',
  PRICE_RANGE_MAX: 'priceRange.max',
  COLOR: 'variants.color.hex',
  LABEL_SIZES: 'labelSizes',
  MINIMUM_ORDER_QTY: 'minimumOrderQuantity',
  CAN_BE_EXPORTED: 'canBeExported',
  DECORATION_OPTIONS: 'decorationOptions',
  STANDARD_PRODUCTION_TURNAROUND: 'standardProductionTurnAround',
  CATEGORY: 'categories.name',
  SUB_CATEGORY: 'categories.subCategories'
};

export const toSnakeCase = str => {
  if (str.includes('/')) return str.toLowerCase().replace(' ', '_');
  return str.replace(/[A-Z]/g, match => `_${match.toLowerCase()}`).replace(/^\_/, '');
};
