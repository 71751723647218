import { round } from 'lodash';
import { useLocation } from 'react-router-dom';

export const getPlatinumProductDiscount = memberships => {
  if (!Array.isArray(memberships)) {
    console.error('Input is not a valid array.');
    return null;
  }
  const platinumProgram = memberships.find(program => program?.name === 'Platinum');
  if (platinumProgram) {
    return platinumProgram?.product_discount;
  }
  console.log('No Platinum membership found.');
  return null;
};

export const getPlatinumProductDiscountPrice = (price, productDiscount) => {
  const discountValue = (price * productDiscount) / 100;
  return round(discountValue, 2);
};

export const getLastPathSegment = url => {
  const segments = url?.split('/');
  const lastSegment = segments?.length > 0 ? decodeURIComponent(segments[segments?.length - 1]) : '';

  return lastSegment;
};
