/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import { AppBar, Badge, Grid, IconButton, Toolbar, makeStyles, Hidden } from '@material-ui/core';
import { ShoppingCart } from '@material-ui/icons';
import { Button, Header as HeaderLogo, Typography } from '@swagup-com/components';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFlags } from 'launchdarkly-react-client-sdk';
import PersonSharpIcon from '@material-ui/icons/PersonSharp';
import logo from '../../assets/images/logo/black-logo-with-name.svg';
import { useResponsive } from '../../hooks';
import { Link } from '../../shared';
import { shoppingExperienceColors } from '../../shared/constants';
import { HamburgerMenuIcon, PersonIcon, SearchIcon, SwagUpLogoMobileIcon } from '../../shared/icons';
import { NavigateTo } from '../../shared/ui/common';
import { useHeaderStyles } from './header.styles';
import MobileMenu from './mobileMenu';
import MobileSearch from './mobileSearch';
import SearchComponent from '../../shared/ui/searchComponent';
import TopSearchDrawer from '../../shared/ui/topSearchDrawer';
import { setInitialUrl, toggleDrawer } from '../../redux/searchReducer';
import { setPreviousPath } from '../../redux/pathReducer';
import { useCartContext } from '../../context/cart.context';
import { useAuth } from '../../../components/global/Authentication/AuthProvider';
import { useProfileCore } from '../../../hooks/globalQueries';
import AccountMenuDropdown from '../AccountMenu/accountMenu';
import { globalApi } from '../../../apis/swagup';

const useStyles = makeStyles(() => ({
  customToolbar: {
    padding: '0px !important'
  },
  desktopHeader: {
    padding: '30px 40px',
    height: '100px'
  },
  desktopHeaderLogo: {
    height: '38px',
    width: '160px',
    objectFit: 'contain'
  },
  mobileHeader: { padding: '12px 20px' }
}));

const Header = props => {
  const { isPending, isAuthenticated, logout } = useAuth();
  const [open, setOpen] = React.useState(false);
  const { swagupMembershipPlans } = useFlags();
  const { data: profile, isFetching, isError, refetch } = useProfileCore();
  const { currentAccount } = profile ?? {};

  const { onOpen, hideMenu } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const [darkMode, setDarkMode] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [openMobileSearch, setOpenMobileSearch] = useState(false);
  const { isDesktop, isMobile } = useResponsive();
  const headerClasses = useHeaderStyles({ isDesktop });
  const location = useLocation();
  const authenticated = !isPending && isAuthenticated;
  const { cartProducts } = useCartContext();

  const classes = useStyles();

  const menuList = [
    { label: 'Collections', link: null, isActive: history?.location?.pathname?.includes('/catalog/collections') },
    { label: 'All Swag', link: '/catalog', isActive: history?.location?.pathname === '/catalog' },
    { label: 'Apparel', link: '/catalog/apparel', isActive: history?.location?.pathname?.includes('/apparel') },
    { label: 'Drinkware', link: '/catalog/drinkware', isActive: history?.location?.pathname?.includes('/drinkware') },
    { label: 'Bags', link: '/catalog/bags', isActive: history?.location?.pathname?.includes('/bags') },
    { label: 'Special Request', link: 'https://www.swagup.com/submit-a-request', isExternal: true }
  ];

  useEffect(() => {
    const pathName = location.pathname.trim();
    if (!pathName.includes('search')) {
      dispatch(setInitialUrl(pathName));
    }
    dispatch(setPreviousPath(location.pathname));
  }, [location.pathname]);

  const mobileMenuList = [
    ...menuList,
    { label: 'Profile', link: '/settings/profile', isActive: null },
    ...(isAuthenticated
      ? [
          { label: 'Log Out', link: null, isActive: null } // Add Sign Out if authenticated
        ]
      : [
          { label: 'Sign In', link: null, isActive: null } // Add Sign In if not authenticated
        ])
  ];

  const handleSearchClick = () => {
    dispatch(toggleDrawer(true));
  };

  const TabletSearchBar = () => {
    return (
      <IconButton onClick={handleSearchClick}>
        <SearchIcon />
      </IconButton>
    );
  };

  useEffect(() => {
    isAuthenticated && refetch();
  }, []);

  const handleAccountSwap = async accountId => {
    await globalApi.setAccount(profile.id, { current_account: accountId });
    const newLocation = `/dashboard${history?.location?.pathname}`;
    window.location.assign(`${window.location.origin}${newLocation}`);
  };

  const handleClose = () => {
    setOpen(!open);
  };

  return isMobile ? (
    <>
      <AppBar position="fixed" color={shoppingExperienceColors.white} className={headerClasses.mobileHeader}>
        {hideMenu ? (
          <Toolbar className={classes.customToolbar}>
            {/* Mobile View */}
            <Hidden mdUp>
              <Grid container alignItems="center" justify="space-between" className={classes.mobileHeader}>
                <Grid item onClick={() => NavigateTo(history, '/')}>
                  <SwagUpLogoMobileIcon />
                </Grid>
                <Grid item>
                  {isAuthenticated && profile ? (
                    <AccountMenuDropdown
                      profile={profile}
                      currentAccount={currentAccount}
                      logout={logout}
                      handleAccountSwap={handleAccountSwap}
                      isAuthenticated={isAuthenticated && swagupMembershipPlans}
                      setOpen={handleClose}
                      open={open}
                      classes={headerClasses}
                    />
                  ) : (
                    <IconButton onClick={onOpen}>
                      <PersonSharpIcon
                        style={{ color: shoppingExperienceColors.black, cursor: 'pointer' }}
                        onClick={onOpen}
                      />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Hidden>

            {/* Tablet, Laptop, and Desktop View */}
            <Hidden smDown>
              <Grid container alignItems="center" justify="space-between" className={classes.desktopHeader}>
                <Grid item onClick={() => NavigateTo(history, '/')} style={{ cursor: 'pointer' }}>
                  <img src={logo} alt="Logo" className={classes.desktopHeaderLogo} />
                </Grid>
                <Grid item>
                  <PersonSharpIcon
                    style={{ color: shoppingExperienceColors.black, cursor: 'pointer' }}
                    onClick={onOpen}
                  />
                </Grid>
              </Grid>
            </Hidden>
          </Toolbar>
        ) : (
          <Toolbar>
            <Grid container xs={12} justifyContent="space-between" alignItems="center">
              <Grid justifyContent="flex-start">
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  aria-label="menu"
                  sx={{ mr: 2 }}
                  onClick={() => setOpenMenu(true)}
                >
                  <HamburgerMenuIcon />
                </IconButton>
              </Grid>
              <Grid style={{ margin: '5px 0px 0px 32px' }}>
                <SwagUpLogoMobileIcon onClick={() => NavigateTo(history, '/')} />
              </Grid>
              <Grid justifyContent="flex-end">
                <Grid container justifyContent="space-between" xs={12}>
                  <Grid item={6} style={{ paddingRight: '16px' }} onClick={() => setOpenMobileSearch(true)}>
                    <SearchIcon />
                  </Grid>
                  <Grid item={6}>
                    <Badge badgeContent={cartProducts?.length} color="primary">
                      <ShoppingCart
                        onClick={() => NavigateTo(history, '/cart')}
                        style={{ color: shoppingExperienceColors.textPrimary }}
                      />
                    </Badge>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        )}
      </AppBar>
      <MobileMenu
        open={openMenu}
        onClose={() => setOpenMenu(false)}
        menus={mobileMenuList}
        onOpen={onOpen}
        isAuthenticated={isAuthenticated}
        logout={logout}
      />
      <MobileSearch open={openMobileSearch} onClose={() => setOpenMobileSearch(false)} />
    </>
  ) : (
    <>
      <HeaderLogo
        classes={headerClasses}
        logo={
          isDesktop
            ? darkMode
              ? { link: '/', image: '/images/public/white-logo.svg' }
              : { link: '/', image: '/images/public/su-logo-dark.svg' }
            : {
                link: '/',
                image: '/images/public/su-logo-icon.svg'
              }
        }
      >
        <Grid container justifyContent="space-evenly" alignItems="center">
          <Grid
            container
            xs={12}
            justifyContent={hideMenu ? 'flex-end' : 'space-evenly'}
            style={{ padding: 'auto 40px' }}
          >
            {hideMenu === false && (
              <Grid container xs={8} sm={isDesktop ? 8 : 9} alignItems="center" justifyContent="space-evenly">
                {menuList.map(menu => (
                  <Button
                    key={menu.label}
                    variant="text"
                    component={menu.label === 'Collections' ? 'div' : Link}
                    to={menu.link ? { pathname: menu.link } : undefined}
                    onClick={() => {
                      if (menu.isExternal) {
                        window.open(menu.link, '_blank');
                      } else if (menu.label === 'Collections') {
                        NavigateTo(history, '/catalog/collections');
                      } else if (menu.link) {
                        NavigateTo(history, menu.link);
                      }
                    }}
                  >
                    <Typography
                      variant={isDesktop ? 'body2MediumInter' : 'body4MediumInter'}
                      component="div"
                      style={{
                        ...(menu.isActive ? { color: shoppingExperienceColors.primary } : { cursor: 'pointer' })
                      }}
                    >
                      {menu.label}
                    </Typography>
                  </Button>
                ))}
              </Grid>
            )}
            <Grid container xs={4} sm={isDesktop ? 4 : 3} justifyContent="flex-end" alignItems="center">
              {isDesktop ? hideMenu === false && <SearchComponent /> : hideMenu === false && <TabletSearchBar />}
              {isAuthenticated && profile ? (
                <AccountMenuDropdown
                  profile={profile}
                  currentAccount={currentAccount}
                  logout={logout}
                  handleAccountSwap={handleAccountSwap}
                  isAuthenticated={isAuthenticated && swagupMembershipPlans}
                  setOpen={handleClose}
                  open={open}
                  classes={headerClasses}
                />
              ) : (
                <IconButton onClick={onOpen}>
                  <PersonIcon />
                </IconButton>
              )}
              {hideMenu === false && (
                <IconButton onClick={() => NavigateTo(history, '/cart')}>
                  <Badge badgeContent={cartProducts?.length} color="primary">
                    <ShoppingCart style={{ color: shoppingExperienceColors.textPrimary }} />
                  </Badge>
                </IconButton>
              )}
            </Grid>
          </Grid>
        </Grid>
      </HeaderLogo>
      <TopSearchDrawer open={openMobileSearch} onClose={() => setOpenMobileSearch(false)} />
    </>
  );
};

export default Header;
