import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Typography, Button } from '@swagup-com/components';
import { useHistory } from 'react-router-dom';
import { shoppingExperienceColors } from '../../shared/constants';
import { NavigateTo } from '../../shared/ui/common';

const useStyles = makeStyles(theme => ({
  bannerContainer: {
    backgroundColor: shoppingExperienceColors.textPrimary,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    position: 'relative',
    overflow: 'hidden',
    width: '100%',
    marginBottom: '24px',
    borderRadius: '6px',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      borderRadius: '0px'
    },
    [theme.breakpoints.between(800, 960)]: {
      height: '108px'
    },
    [theme.breakpoints.between(960, 1080)]: {
      height: '130px'
    },
    [theme.breakpoints.up('xl')]: {
      height: '130px'
    }
  },
  closeButton: {
    position: 'absolute',
    top: '-5px',
    right: '-5px',
    color: shoppingExperienceColors.white
  },
  imageContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'relative',
    flexShrink: 0
  },
  bannerImageOne: {
    width: '100%',
    height: '120px',
    objectFit: 'contain',
    [theme.breakpoints.down('lg')]: {
      height: '100px'
    }
  },
  bannerImageTwo: {
    width: '100%',
    objectFit: 'contain',
    [theme.breakpoints.down('sm')]: {
      height: 'auto'
    },
    [theme.breakpoints.between(800, 960)]: {
      height: '108px'
    },
    [theme.breakpoints.between(960, 1080)]: {
      height: '130px'
    },
    [theme.breakpoints.up('xl')]: {
      height: '130px'
    }
  },
  textSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  heading: {
    fontSize: '27px',
    color: shoppingExperienceColors.white,
    [theme.breakpoints.down('sm')]: {
      fontSize: '18px'
    }
  },
  subText: {
    color: shoppingExperienceColors.white,
    fontWeight: '400 !important',
    fontSize: '22px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px'
    }
  },
  isLive: {
    color: '#FFB6C1',
    fontWeight: 700
  },
  button: {
    width: '160px',
    height: '40px'
  }
}));

const NewShoppingExpBanner = () => {
  const classes = useStyles();
  const [visible, setVisible] = useState(true);
  const history = useHistory();

  if (!visible) return null;

  return (
    <Grid container className={classes.bannerContainer}>
      <IconButton className={classes.closeButton} onClick={() => setVisible(false)}>
        <CloseIcon />
      </IconButton>
      <Grid item xs={12} sm={2} md={2} lg={2} className={classes.imageContainer}>
        <img
          src="/images/dashboard/new-shopping-exp-banner.png"
          alt="First Banner"
          width={100}
          height={100}
          className={classes.bannerImageOne}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={5} lg={5} className={classes.textSection}>
        <Typography variant="h2BoldInter" className={classes.heading}>
          Effortless Swag Shopping Starts Here
        </Typography>
        <Typography variant="h5BoldInter" className={classes.subText}>
          OUR NEW SHOPPING EXPERIENCE <span className={classes.isLive}>IS LIVE!</span>
        </Typography>
      </Grid>
      <Grid item xs={12} sm={2} md={2} lg={3} className={classes.imageContainer}>
        <img
          src="/images/dashboard/new-shopping-exp-banner-2.png"
          alt="Second Banner"
          width={100}
          height={100}
          className={classes.bannerImageTwo}
        />
      </Grid>
      <Grid item xs={12} sm={2} md={2} lg={2} justifyContent="center" style={{ display: 'flex' }}>
        <Button
          id="Shop-now-NSE"
          variant="contained"
          color="primary"
          className={`${classes.button}`}
          onClick={() => NavigateTo(history, '/start-project')}
          style={{ backgroundColor: shoppingExperienceColors.primary }}
        >
          <Typography variant="h6SemiBoldInter" style={{ color: shoppingExperienceColors.white }}>
            Shop Now!
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default NewShoppingExpBanner;
