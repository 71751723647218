import React, { useState, useEffect } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useQuery } from 'react-query';
import Product from '../Product/product';
import styles from './CollectionsCard.styles';
import Pagination from '../Pagination/pagination';
import Modal from '../../shared/ui/modal';
import CartPopup from '../CartPopup/cartPopup';
import { HandleNextPage, HandlePreviousPage } from '../../shared/ui/common';
import { fetchCollectionProducts } from '../../apis';
import PaginationButton from '../../shared/ui/paginationButton';
import { MAX_PRODUCT_PER_PAGE } from '../../utils/constants';
import { useCartContext } from '../../context/cart.context';
import { getPriceForQuantity } from '../../utils/getPriceForQuantity';

const useStyles = makeStyles(styles);

const CollectionsCard = ({ selectedCollectionId, setTotalProducts, groupedFilter = [], sortItem }) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(location.search);

  const [width] = useState(window.innerWidth);
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('page')) || 1);
  const [renderedProducts, setRenderedProducts] = useState([]);
  const [loadedProducts, setLoadedProducts] = useState({});
  const [totalProductsValue, setTotalProductsValue] = useState(0);
  const [isShowPreviousButton, setIsShowPreviousButton] = useState(false);
  const isTouchDevice = Math.min(width) < 1025 && Math.min(width) > 786;
  const isMobile = Math.min(width) < 786;
  const [progress, setProgress] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const { isPack, qtyForPack } = useCartContext();
  const { data: { products = [], totalProducts = 0 } = {}, isLoading, refetch } = useQuery(
    ['collectionProducts', selectedCollectionId, currentPage],
    () =>
      selectedCollectionId &&
      fetchCollectionProducts(
        selectedCollectionId,
        MAX_PRODUCT_PER_PAGE,
        MAX_PRODUCT_PER_PAGE * (currentPage - 1),
        groupedFilter,
        sortItem
      ),
    {
      keepPreviousData: true
    }
  );

  useEffect(() => {
    if (totalProducts !== totalProductsValue && totalProducts !== 0) {
      setTotalProducts(totalProducts);
      setTotalProductsValue(totalProducts);
    }
    if (products?.length) {
      const _loadedProducts = { ...loadedProducts, ...{ [currentPage]: products } };
      setLoadedProducts(_loadedProducts);
      const loadedPages = Object.keys(_loadedProducts);
      if (loadedPages.length) {
        const isPreviousDataNotLoaded = loadedPages[0] - 1 !== 0;
        setIsShowPreviousButton(isPreviousDataNotLoaded);
        setRenderedProducts(Object.values(_loadedProducts).flat());
      }
    }
  }, [totalProducts, products]);

  const onOpen = product => {
    setOpenModal(true);
    const { quantity } = product;
    const price = getPriceForQuantity(
      product?.pricingOption?.[0]?.pricingMatrix,
      isPack ? quantity * qtyForPack : quantity
    );
    setSelectedProduct({
      ...product,
      category: product?.categories?.length ? product.categories[0]?.name : '',
      thumbnailURL: product?.media?.length ? product.media[0].url : '',
      price,
      quantity,
      color: product?.color?.hex,
      colorName: product?.color?.colorName,
      decorationMethod: product?.decorationOptions[0] || '',
      isHazmat: product?.hazmatDetails?.isHazmat || false
    });
  };

  const onClose = () => {
    setOpenModal(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    setProgress((renderedProducts?.length / totalProducts) * 100);
  }, [renderedProducts, totalProducts]);

  const showMoreHandler = () => {
    const page = HandleNextPage(currentPage);
    if (renderedProducts?.length <= totalProducts) setCurrentPage(page);
  };

  const showPreviousHandler = () => {
    const page = HandlePreviousPage(currentPage);
    if (page === 1) setIsShowPreviousButton(false);
    setCurrentPage(page);
  };

  useEffect(() => {
    refetch();
  }, [groupedFilter, sortItem]);

  return (
    <>
      <Modal title="Product added to cart" open={openModal} onClose={onClose} innerClasses={{ paper: classes.dialog }}>
        {selectedProduct && (
          <CartPopup onClose={onClose} details={selectedProduct} isTouchDevice={isTouchDevice} isMobile={isMobile} />
        )}
      </Modal>
      <Grid container spacing={2}>
        {isShowPreviousButton && (
          <Grid style={{ marginBottom: 20, justifyContent: 'center', width: '100%', display: 'inline-flex' }}>
            {' '}
            <PaginationButton title="Show previous" showMore={false} showPreviousHandler={showPreviousHandler} />{' '}
          </Grid>
        )}
        {(!isLoading ? renderedProducts : new Array(8).fill(''))?.map((product, ind) => (
          <Grid item xs={6} md={3} sm={6} lg={3} key={ind} className={classes.collectionsWrapper}>
            <Product
              details={product}
              isTouchDevice={isTouchDevice}
              isMobile={isMobile}
              onOpen={() => onOpen(product)}
            />
          </Grid>
        ))}
      </Grid>
      <Pagination
        progress={progress}
        renderedProducts={renderedProducts?.length}
        totalProducts={totalProductsValue}
        showMoreHandler={showMoreHandler}
      />
    </>
  );
};

export default CollectionsCard;
