import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useResponsive } from '../../hooks';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    padding: ({ isMobile }) => {
      if (isMobile) return '0px';
      return '0px 40px';
    }
  },
  bannerImage: {
    maxWidth: '100%',
    objectFit: 'cover',
    borderRadius: ({ isMobile }) => {
      if (isMobile) return '0px';
      return '5px';
    }
  }
}));

const CollectionsBannerImage = ({ imageUrl }) => {
  const { isMobile, isTablet, isLaptop } = useResponsive();
  const classes = useStyles({ isMobile, isTablet, isLaptop });

  return (
    <Grid container className={classes.container}>
      <LazyLoadImage
        src={imageUrl}
        alt="Collections Banner"
        effect="blur"
        style={{
          maxWidth: '100%',
          objectFit: 'cover',
          borderRadius: () => {
            if (isMobile) return '0px';
            return '5px';
          }
        }}
      />
    </Grid>
  );
};

export default CollectionsBannerImage;
